import { useEffect, useState } from "react";
import NextImage from "next/image";
import { useRouter } from "next/router";
import { NextSeo } from "next-seo";
import LoginForm from "domains/auth/components/LoginForm";
import isBreakpointMobile from "domains/commons/isMobile";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  Box,
  Flex,
  FlexProps,
  HStack,
  Image as ChakraImage,
  Link,
  Text,
  useBreakpoint,
} from "@chakra-ui/react";

import loginBackground from "/public/login-wallpaper.png";

export const ScenarioLogo = ({ ...props }: FlexProps) => (
  <Flex align="center" {...props} gap={2}>
    <ChakraImage h={"36px"} alt={"logo"} src={"logos/scenario.svg"} />
    <Text
      fontSize={25}
      fontWeight={600}
      textAlign={"center"}
      // prevent selecting text
      userSelect="none"
    >
      Scenario
    </Text>
  </Flex>
);

export default function LoginPage() {
  const router = useRouter();

  const breakpoint = useBreakpoint({ ssr: true });
  const isMobile = isBreakpointMobile(breakpoint);

  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false);

  useEffect(() => {
    const { referral } = router.query;

    if (referral && typeof referral === "string") {
      Track(AnalyticsEvents.Signup.ReferralCaptured, {
        referral,
      });
    }
  }, [router]);

  return (
    <>
      <NextSeo title="Login" />

      <Flex w="100vw" h="100vh" bgGradient="linear(to-r, #000000, #20041f)">
        <ScenarioLogo position="absolute" top={8} left={8} />

        <Flex
          zIndex={"overlay"}
          direction={"column"}
          gap={8}
          w={isMobile ? "100vw" : "50vw"}
          maxW="400px"
          mx={isMobile ? 1 : "10vw"}
          my="auto"
          p={isMobile ? 4 : 8}
          bg={"background.500"}
          border="1px solid"
          borderColor="border.500"
          borderRadius="lg"
        >
          <Box w="100%">
            <LoginForm />
          </Box>

          <Text align="center" size="body.md">
            {"By using Scenario, you agree to our "}
            <Link
              color="primary.500"
              href="https://www.scenario.com/terms-and-conditions"
              target="_blank"
            >
              Terms and Conditions
            </Link>
            {" and "}
            <Link
              color="primary.500"
              href="https://www.scenario.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Link>
            .
          </Text>

          <HStack
            alignItems={"center"}
            w="max-content"
            px={3}
            py={1}
            borderRadius="24px"
            marginX="auto"
          >
            <Text fontSize={"0.7em"}>Powered by</Text>

            <ChakraImage w="auto" h="18px" alt="Auth0" src="/logos/auth0.png" />
          </HStack>
        </Flex>

        <Box
          pos={"absolute"}
          zIndex={"overlay"}
          // Avoiding the chat button on production
          right={"100px"}
          bottom={2}
          display={isMobile ? "none" : "block"}
          p={8}
          opacity={backgroundImageLoaded ? 1 : 0}
          backdropFilter="blur(10px)"
          bgColor={"blackAlpha.500"}
          transitionDuration={"0.5s"}
        >
          <Text mb={4} fontWeight={"medium"}>
            {`From "Realistic SciFi Concept Art by Araminta"`}
          </Text>

          <Text>
            {`"a close up of a person in a space suit with a modern helmet, cat
                ears"`}
          </Text>
        </Box>

        <NextImage
          onLoad={() => setBackgroundImageLoaded(true)}
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            maxHeight: "min(100vh, 80vw)",
            width: "auto",
          }}
          placeholder="blur"
          alt={"login wallpaper"}
          src={loginBackground}
        />
      </Flex>
    </>
  );
}
